<template>
  <div ref="map" :class="$style.map">
    <div ref="myChart" style="width: 100%; height: 100%" />
    <div :class="$style.langed">
      <div
        v-for="(item, key) in langed"
        :key="key"
        :class="$style.content"
        @click="handleLanged(key)"
      >
        <div
          :class="$style.circle"
          :style="[
            item.selected
              ? { backgroundColor: item.color }
              : { backgroundColor: '#929292' },
          ]"
        ></div>
        <div>{{ item.text }}</div>
      </div>
    </div>
    <div :class="$style.backMapBtn" v-if="mapUrlQueueSize !== 1">
      <span :class="$style.backMapLabel" @click="backTopMapArea">
        返回上一级
      </span>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { getGeoData } from '@/services/oss/oss.js';
import { crossStorageModule } from '@/enum/store.js';
import theme from '@/utils/theme/theme';
import { gcj02tobd09 } from './utils';
import { getAddressInfoByAmap } from '@/services/device-manage/map';
import { nowSize } from '@/utils/common';
import screenfull from '../components/full-screen';
/** @name 未开始项目 */
const NOT_BEGIN = 'NOT_STARTED';
/** @name 进行中项目 */
const RUNNING = 'CONSTRUCTION';
/** @name 已完成项目  */
const FINISH = 'COMPLETED';
// 地图的级别
/** @name 地图的级别：全国 */
export const LEVEL_TYPE_COUNTRY = 'CHINA';
/** @name 地图的级别：省 */
export const LEVEL_TYPE_PROVINCE = 'PROVINCE';
/** @name 地图的级别：市 */
export const LEVEL_TYPE_CITY = 'CITY';
/** @name 缓存项目 */
const projectListMap = new Map();
// 颜色变量
/** @name 未开始项目 */
export const Color_NotBegin = '#fea851';
/** @name 进行中项目 */
export const Color_Running = '#37D7DB';
/** @name 已完成项目 */
export const Color_Finish = '#FFFFFF';

@Component()
export default class ChinaMap extends Vue {
  @crossStorageModule.State('skin') skin;
  @Prop({ type: Boolean, default: false }) isFullScreen;
  @Prop({ type: Object, default: () => {} }) mapAddress;
  @Prop({ type: Array, default: () => [] }) projectListMap;

  @Watch('skin')
  changeSkin() {
    this.initChart();
  }
  @Watch('isFullScreen')
  changeScreen(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.initChart();
    }
  }
  @Watch('mapAddress', { deep: true })
  changeMapAddress(newVal) {
    if (newVal) {
      this.mapAddress = newVal;
    }
  }
  @Watch('projectListMap', { deep: true })
  changeProjectList(newVal) {
    if (newVal) {
      this.projectListMap = newVal;
      this.initChart();
    }
  }
  mounted() {
    this.init();
  }

  /**
   * @Description 判断地图size
   * @param flag 是否是全屏
   * @param geo 当前选中区域
   */
  chartResize(flag, geo) {
    setTimeout(() => {
      const rect = this.$refs.map.getBoundingClientRect();
      const opt = {
        width: rect.width,
        height: rect.height,
      };
      if (this.myChart) {
        const options = this.myChart.getOption();
        if (flag) {
          options.geo[0].zoom = geo === 'china' ? 1.2 : 1;
        } else {
          options.geo[0].zoom = geo === 'china' ? 1.3 : 1;
        }
        // options.geo[0].left = flag ? '18%' : '20%';
        this.myChart.setOption(options, true);
        this.myChart.resize(opt);
      }
    }, 100);
  }
  langed = {
    notBegin: {
      text: '未开始',
      color: Color_NotBegin,
      selected: true,
    },
    running: {
      text: '进行中',
      color: Color_Running,
      selected: true,
    },
    finish: {
      text: '已竣工',
      color: Color_Finish,
      selected: true,
    },
  };
  selectLanged = '';
  handleLanged(key) {
    this.selectLanged = key;
    this.langed[key].selected = !this.langed[key].selected;
    const option = this.myChart.getOption();
    const obj = {
      notBegin: 0,
      running: 1,
      finish: 2,
    };
    const idx = obj[key];
    option.series[idx].data = this.langed[key].selected
      ? this.projectObject[key + 'List']
      : [];
    this.myChart.setOption(option);
  }
  myChart = null;
  projectObject = {};
  init() {
    if (this.mapUrlQueue.length === 0) {
      this.mapUrlQueue.push({
        level: this.type,
        adcode: '100000',
        value: '全国',
      });
    } else {
      /**
       * 1、设置type类型（当前所处地图层级）
       */
      this.type = this.lastMapUrlQueueItem.level;
    }
    this.loadRegion();
    this.initChart();
    screenfull.onchange(() => this.initChart());
  }
  /**
   * 大屏地图首次加载跳转到配置好的区域
   */
  loadRegion() {
    const region = this.$store.state.screen.region;
    let areaName = '';
    if (region?.province) {
      this.type = LEVEL_TYPE_PROVINCE;
      this.mapUrlQueue.push({
        level: this.type,
        adcode: region.provinceCode,
        value: region.province,
      });
      areaName = region.province;
    }
    if (region?.city) {
      this.type = LEVEL_TYPE_CITY;
      this.mapUrlQueue.push({
        level: this.type,
        adcode: region.cityCode,
        value: region.city,
      });
      areaName = region.city;
    }
    if (areaName) {
      this.$emit('dbAdress', areaName);
    }
  }
  type = LEVEL_TYPE_COUNTRY;
  selected = 'china';
  countryMapName = 'china';
  provineMapName = '';
  cityMapName = '';
  defaultOptions(geo) {
    const themeMode = theme['dark'];
    const themeColor = theme['dark'];
    const commonToolTipStyle = {
      backgroundColor: themeMode.mainBg,
      trigger: 'item',
      textStyle: {
        color: themeMode.font,
        fontSize: nowSize(14),
      },
      extraCssText: 'border-radius:5px;border-color: var(--border)',
      padding: [nowSize(10)],
    };
    const commonObj = {
      symbol: 'circle',
      type: 'scatter', // series图表类型 effectScatter动画
      coordinateSystem: 'geo',
      data: [],
      // 点名称配置
      // label: {
      //   formatter: e => e.name,
      //   position: 'left',
      //   show: true,
      //   textStyle: {
      //     color: 'white',
      //     fontSize: 16,
      //     fontWeight: 800,
      //   },
      // },
      tooltip: {
        ...commonToolTipStyle,
        trigger: 'item',
        formatter: this.formatSeriesObject,
      },
    };
    const { notBeginList, runningList, finishList } = this.projectObject;
    return {
      tooltip: {
        ...commonToolTipStyle,
        formatter: this.getFormatter,
      },
      geo: {
        map: geo,
        roam: true,
        zoom: geo === 'china' ? (screenfull.isFullscreen ? 1.2 : 1.3) : 1,
        top: geo === 'china' ? '20%' : '5%',
        // left: '20%',
        label: {
          color: themeMode.font,
          show: true, // 地图区域名字是否显示
        },
        itemStyle: {
          // opacity: 0.7,
          // borderColor: themeMode.mapBorderColor,
          // areaColor: themeMode.mapAreaColor,
          borderColor: '#D7E9FF',
          borderWidth: 2,
          areaColor: 'rgba(30, 80, 121, 0.5)',
          // shadowOffsetX: 2,
          // shadowOffsetY: 3,
          // shadowColor: '#D7E9FF',
        },
        scaleLimit: {
          min: 1,
          max: 5,
        },
        emphasis: {
          itemStyle: {
            // areaColor: themeColor.primary60,
            shadowColor: themeColor.primary50,
            areaColor: 'rgba(30, 80, 121, 1)',
          },
          label: {
            color: themeMode.font,
          },
        },
        select: {
          itemStyle: {
            // areaColor: themeColor.primary60,
            areaColor: 'rgba(30, 80, 121, 1)',
          },
          label: {
            color: themeMode.font,
          },
        },
      },
      series: [
        Object.assign({}, commonObj, {
          data: notBeginList,
          symbolSize: nowSize(12),
          z: 11,
          itemStyle: {
            color: Color_NotBegin,
          },
        }),
        Object.assign({}, commonObj, {
          data: runningList,
          symbolSize: nowSize(12),
          z: 10,
          itemStyle: {
            color: Color_Running,
          },
        }),
        Object.assign({}, commonObj, {
          data: finishList,
          symbolSize: nowSize(12),
          z: 14,
          itemStyle: {
            color: Color_Finish,
          },
        }),
        // 点高亮配置
        // Object.assign({}, commonObj, {
        //   type: 'effectScatter', // series图表类型 effectScatter动画
        //   data: [
        //     {
        //       name: 'mark',
        //       value: [116.1, 24.55],
        //     },
        //   ],
        //   symbolSize: nowSize(16),
        //   z: 14,
        //   itemStyle: {
        //     color: 'red',
        //   },
        // }),
      ],
    };
  }

  /**
   * @Description 地图 toolTip
   * @param params
   * @returns {string}
   */
  getFormatter(params) {
    const name = params.name;
    const data = this.mapAddress[name];
    const result = {
      p: data?.projectCount || 0,
      d: data?.deviceCount || 0,
      w: data?.todayAlarm || 0,
    };
    let str = `<div class="toolTipTitle">${name}</div>`;
    str += `
        <div class=toolTipBuild>
          <div class=labelTip>项目总数</div>
          <div class=valueTip>${result.p}</div>
        </div>
        <div class=toolTipBuild>
          <div class=labelTip>设备总数</div>
          <div class=valueTip>${result.d}</div>
        </div>
        <div class=toolTipBuild>
          <div class=labelTip>当天警报</div>
          <div class=valueTip>${result.w}</div>
        </div>`;
    return `
      <div class="screenAreaBox">
        ${str}
      </div>
    `;
  }
  formatSeriesObject(params) {
    const data = params.data;
    const projectName = data.projectName;
    const address = data.detailAddress;
    const alarmCount = data.alarmCount;
    let str = `<div class="toolTipTitle projectTitle">${projectName}</div>`;
    str += `
        <div class=toolTipBuild>
          <div class=labelTip>项目地址</div>
          <div class="valueTip projectTip">
          	<div class="address">
          		${address}
          	</div>
					</div>
        </div>
        <div class=toolTipBuild>
          <div class=labelTip>当天警报</div>
          <div class=valueTip>${alarmCount}</div>
        </div>`;
    return `
      <div class="screenProjectBox">
        ${str}
      </div>
    `;
  }
  async initChart() {
    if (!this.myChart) {
      const chart = this.$refs.myChart;
      this.myChart = echarts.init(chart, null, { devicePixelRatio: 2 });
    }
    this.projectObject = this.formatProjectData(this.projectListMap);
    projectListMap.set(this.getMapRegisterName(this.type), this.projectObject);
    await this.registerMap();
    const option = this.defaultOptions(this.selected);
    this.myChart.setOption(option, true);
    await this.chartResize(this.isFullScreen, this.selected);
    // 监听事件
    this.handleEchartsEventListener();
  }
  get lastMapUrlQueueItem() {
    return this.mapUrlQueue[this.mapUrlQueue.length - 1];
  }
  async registerMap() {
    try {
      let selected = 'china';
      switch (this.type) {
        case LEVEL_TYPE_COUNTRY:
          selected = this.countryMapName;
          break;
        case LEVEL_TYPE_PROVINCE:
          selected = this.provineMapName;
          break;
        case LEVEL_TYPE_CITY:
          selected = this.cityMapName;
          break;
      }

      let areaJson;
      if (this.lastMapUrlQueueItem.adcode === '100000') {
        areaJson = await getGeoData('100000');
      } else {
        areaJson = await getGeoData(
          this.lastMapUrlQueueItem.adcode || '100000',
        );
      }
      echarts.registerMap(selected, areaJson);
      this.selected = selected;
    } catch (error) {
      return 'china';
    }
  }

  /**
   * @Description 监听点击地图事件
   */
  handleEchartsEventListener() {
    this.myChart.off('click'); // 点击事件之前，要释放之前的点击事件
    this.myChart.off('dblclick');
    // 单击事件
    // this.myChart.on('click', params => {});
    // 双击事件切换地图
    this.myChart.on('dblclick', async params => {
      // 地图层级目前最多切换到市级
      if (this.mapUrlQueueSize === 3) {
        return;
      }
      await this.handleMapUrl(params);
      await this.initChart();
      this.$emit('dbAdress', params.name);
    });
  }
  /** @names 格式化省市名称 */
  formatProvinceName(address) {
    if (`${address}`.includes('省')) {
      return address;
    } else {
      if (address === '北京') {
        return `${address}`;
      } else if (
        address === '天津' ||
        address === '重庆' ||
        address === '上海'
      ) {
        return `${address}市`;
      } else if (address === '广西') return '广西壮族自治区';
      else if (address === '西藏') return '西藏自治区';
      else if (address === '宁夏') return '宁夏回族自治区';
      else if (address === '新疆') return '新疆维吾尔自治区';
      else if (address === '内蒙古') return '内蒙古自治区';
      else return `${address}省`;
    }
  }
  /** @names 获取区域编码或者区域名称 */
  getAdcodeByArea(address) {
    return new Promise((resolve, reject) => {
      getAddressInfoByAmap({
        keywords: address,
      }).then(result => {
        if (!result) reject(new Error('no data'));
        let message;
        try {
          const { districts } = JSON.parse(result);
          if (districts[0] && districts[0].adcode) {
            message = districts[0];
          } else {
            message = {
              adcode: '100000',
              name: '中国',
            };
          }
        } catch (error) {
          message = {
            adcode: '100000',
            name: '中国',
          };
        }
        resolve(message);
      });
    });
  }
  mapUrlQueue = [];
  get mapUrlQueueSize() {
    return this.mapUrlQueue.length;
  }
  clickDiff = false;
  backTopMapArea() {
    if (this.clickDiff) return false;
    this.mapUrlQueue.pop();
    if (this.mapUrlQueueSize) {
      const adress = this.mapUrlQueue[this.mapUrlQueueSize - 1].value;
      this.$emit('dbAdress', adress);
    }
    this.type = this.lastMapUrlQueueItem.level;
    switch (this.type) {
      case LEVEL_TYPE_COUNTRY:
        this.countryMapName = 'china';
        this.provineMapName = '';
        this.cityMapName = '';
        break;
      case LEVEL_TYPE_PROVINCE:
        this.provineMapName = this.lastMapUrlQueueItem.value;
        this.cityMapName = '';
        break;
      case LEVEL_TYPE_CITY:
        this.cityMapName = this.lastMapUrlQueueItem.value;
        break;
    }
    this.initChart();
    this.clickDiff = true;
    setTimeout(() => {
      this.clickDiff = false;
    }, 500);
  }
  /**
   * @Description 下钻地图队列
   * @param params
   * @returns {Promise<boolean>}
   */
  async handleMapUrl(params) {
    if (!params.name) {
      return false;
    }
    const { adcode, name } = await this.getAdcodeByArea(params.name);
    if (this.type === LEVEL_TYPE_COUNTRY) {
      const provinceName = this.formatProvinceName(params.name);
      this.type = LEVEL_TYPE_PROVINCE;
      this.provineMapName = provinceName;
      this.mapUrlQueue.push({
        level: this.type,
        adcode,
        value: provinceName,
      });
      return false;
    }

    if (this.type === LEVEL_TYPE_PROVINCE) {
      this.type = LEVEL_TYPE_CITY;
      this.cityMapName = name;
      this.mapUrlQueue.push({
        level: this.type,
        adcode,
        value: name,
      });
      return false;
    }
    if (this.type === LEVEL_TYPE_CITY) {
      return false;
    }
  }
  getMapRegisterName(type) {
    let typeName;
    switch (type) {
      case LEVEL_TYPE_COUNTRY:
        typeName = this.countryMapName;
        break;
      case LEVEL_TYPE_PROVINCE:
        typeName = this.provineMapName;
        break;
      case LEVEL_TYPE_CITY:
        typeName = this.cityMapName;
        break;
      default:
        typeName = this.countryMapName;
    }
    return typeName;
  }
  /** @name 格式化项目数据 */
  formatProjectData(data) {
    const notBeginList = []; // 未开始项目-NOT_BEGIN
    const runningList = []; // 已退场项目-RUNNING
    const finishList = []; // 已进场项目-FINISH
    if (Array.isArray(data)) {
      data.forEach(v => {
        const { lng, lat } = gcj02tobd09(v.longitude, v.latitude);
        if (v.projectStatus === RUNNING) {
          runningList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.projectStatus === FINISH) {
          finishList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.projectStatus === NOT_BEGIN) {
          notBeginList.push({
            ...v,
            value: [lng, lat],
          });
        }
      });
    }
    return {
      notBeginList,
      runningList,
      finishList,
    };
  }
}
</script>

<style lang="less" module>
.map {
  position: relative;
  height: 100%;
  overflow: hidden;
  .langed {
    position: absolute;
    left: 0.2rem;
    bottom: 0.2rem;
    font-size: 0.16rem;
    .content {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0.18rem;
      .circle {
        width: 0.1rem;
        height: 0.1rem;
        border-radius: 50%;
        margin-right: 0.05rem;
      }
    }
  }
  .backMapBtn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.2rem;
    .backMapLabel {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      color: var(--font-active);
      background-color: var(--inline-menu-active);
      border-color: var(--primary-50);
      text-align: center;
      box-sizing: border-box;
      transition: 0.1s;
      font-weight: 500;
      padding: 0.12rem 0.2rem;
      font-size: 0.14rem;
      border-radius: 0.04rem;
    }
    .backMapLabel:hover {
      background-color: rgba(4, 169, 243, 0.3);
    }
  }
}
</style>
<style lang="less">
.screenAreaBox {
  min-width: 1.4rem;
  max-width: 1.8rem;
}
.screenProjectBox {
  min-width: 1.4rem;
  max-width: 4rem;
}
.toolTipTitle {
  font-size: 0.16rem;
  font-weight: bold;
  color: var(--screen-font-3);
}
.projectTitle {
  white-space: break-spaces;
  line-height: 0.24rem;
}
.toolTipBuild {
  display: flex;
  align-items: self-start;
  margin: 0.05rem 0;
  .labelTip {
    background-color: var(--screen-primary);
    border: none;
    width: 0.85rem;
    height: 0.24rem;
    color: var(--screen-font-3);
    border-radius: 0.12rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .valueTip {
    margin-left: 0.1rem;
  }
  .projectTip {
    .address {
      max-width: 3rem;
      white-space: normal;
      word-break: break-all;
    }
  }
}
</style>
