<template>
  <div v-if="!isEmpty" ref="chart" :class="$style.chart"></div>
  <empty-content v-else />
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import * as echarts from 'echarts';
import { Emitter } from '@/views/screen/components/screen-utils';
import { crossStorageModule } from '@/enum/store';
import { nowSize } from '@/utils/common';
import theme from '@/utils/theme/theme';

@Component({
  components: {
    EmptyContent,
  },
})
export default class DeviceBar extends Vue {
  @Prop({ type: Array }) data;
  @Watch('options', { deep: true })
  get isEmpty() {
    return !this.data?.length;
  }
  mounted() {
    this.initChart();
    Emitter.$on('fullscreen', this.chartResize);
  }
  beforeDestroy() {
    Emitter.$off('fullscreen', this.chartResize);
  }
  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  @Watch('data')
  async initChart() {
    if (this.isEmpty) return;
    this.myChart?.dispose();
    await this.$nextTick();
    this.myChart = echarts.init(this.$refs.chart);
    const themeMode = theme['dark'];
    const zoom = this.dataZoom;
    const length = this.data.length;
    if (length === 0) return;
    if (length > 7) {
      zoom[0].show = true;
      const num = Math.round((7 / length) * 10000) / 100.0;
      zoom[0].end = Math.floor(num);
    } else {
      zoom[0].show = false;
      zoom[0].start = 0;
    }
    const options = {
      legend: {
        left: 0,
        top: 0,
        data: ['总数', '异常'],
        textStyle: {
          color: themeMode.font,
        },
        pageTextStyle: {
          color: themeMode.font,
        },
        itemWidth: 5,
        itemHeight: 5,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: themeMode.mainBg,
        textStyle: {
          color: themeMode.font,
          fontSize: nowSize(14),
        },
        extraCssText: 'border-radius:5px;border-color: var(--primary-fade-80)',
        padding: [nowSize(10)],
        position: (pos, params, el, elRect, size) => {
          const obj = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
      },
      grid: {
        left: 0,
        right: 0,
        top: 20,
        bottom: zoom[0].show ? '20%' : '10%',
      },
      xAxis: {
        type: 'category',
        data: this.data.map(item => item.deviceType),
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          color: themeMode.font,
          fontSize: 10,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: themeMode.font,
        },
        splitLine: {
          lineStyle: {
            color: themeMode.border,
          },
        },
      },
      dataZoom: zoom,
      series: [
        {
          name: '总数',
          data: this.data.map(item => item.deviceTotal),
          type: 'bar',
          barWidth: 10,
          itemStyle: {
            color: 'rgba(107, 189, 255, 1)', // 设置柱状图的颜色为红色
          },
        },
        {
          name: '异常',
          data: this.data.map(item => item.errorNumber),
          type: 'bar',
          barWidth: 10,
          itemStyle: {
            color: 'rgba(55, 215, 219, 1)', // 设置柱状图的颜色为红色
          },
        },
      ],
    };
    this.myChart.setOption(options, true);
  }
  chartResize() {
    setTimeout(() => {
      this.myChart?.resize();
    }, 100);
  }

  get dataZoom() {
    return [
      {
        type: 'slider',
        show: true,
        start: 0,
        end: 100,
        xAxisIndex: [0],
        // zoomLock: true, // 不能缩放
        brushSelect: false, // 不能鼠标点击刷选
        height: nowSize(8), // 组件高度
        left: nowSize(10), // 左边的距离
        right: nowSize(30), // 右边的距离
        bottom: 0, // 右边的距离
        fillerColor: '#73BBF5',
        borderColor: '#73BBF5',
        backgroundColor: 'rgba(115, 187, 245, 0.2)',
        showDataShadow: false, // 是否显示数据阴影 默认auto
        showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        handleIcon:
          'path://M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
        filterMode: 'filter',
      },
    ];
  }
}
</script>

<style lang="less" module>
.chart {
  // height: calc(100% - 0.3rem);
  height: 100%;
  padding: 0.16rem;
}
</style>
