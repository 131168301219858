<template>
  <div :class="$style.screenIndex">
    <div :class="$style.left">
      <LayoutCard :class="$style.card" :title="$t('screen.itemStatistics')">
        <LayoutTabs
          slot="action"
          v-model="projectTab"
          :options="[
            { text: '项目', key: 0 },
            { text: '区域', key: 1 },
          ]"
        />
        <PieChart
          v-if="!projectTab"
          :chartData="projectStatisticsList"
          :size="150"
          right="5%"
          left="18%"
        />
        <AreaRank v-else :chart-data="projectConstructionList" chartUnit="个" />
      </LayoutCard>
      <LayoutCard :class="$style.card" title="设备分布情况">
        <DeviceBar :data="deviceCount" />
      </LayoutCard>
      <div :class="$style.card">
        <attendance-ranking
          :dateList="dateList"
          :projectName="projectName"
          ref="attendanceRanking"
        />
      </div>
    </div>
    <div :class="$style.mid">
      <div :class="$style.midTop">
        <div
          v-for="(item, key) in totalList"
          :class="$style.midCardWrap"
          :key="key"
        >
          <div :class="$style.midCard">
            <img :src="item.background" :class="$style.midCardBg" />
            <div>
              <div :class="$style.text">{{ item.text }}</div>
              <div :class="$style.count">
                {{ item.count }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="mapHeight" :class="$style.map">
        <ChinaMap
          :mapAddress="mapAddress"
          :projectListMap="projectListMap"
          :isFullScreen="isFullScreen"
          @dbAdress="getDbAdress"
        />
      </div>
    </div>
    <div :class="$style.right">
      <div :class="$style.card">
        <WarningState
          :dateList="dateList"
          :projectName="projectName"
          :selectedAddress="selectedAddress"
          ref="warningState"
        />
      </div>
      <LayoutCard :class="$style.card" title="参建单位人数">
        <PieChart
          :chartData="
            countMember.companyMembers.map(item => ({
              name: item.name,
              value: item.number,
            }))
          "
          :labelOption="labelOption(getMemberTotal(countMember.companyMembers))"
          :size="150"
          :showEllipsis="true"
          right="5%"
          left="18%"
        />
      </LayoutCard>
      <LayoutCard :class="$style.card" title="工种人数">
        <PieChart
          :chartData="
            countMember.roleMembers.map(item => ({
              name: item.name,
              value: item.number,
            }))
          "
          :labelOption="labelOption(getMemberTotal(countMember.roleMembers))"
          :size="150"
          :showEllipsis="true"
          right="5%"
          left="18%"
        />
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import ChinaMap from '../../e-charts/china-map';
import VirtualList from 'vue-virtual-scroll-list';
import AreaRank from '../../e-charts/area-rank';
import CommonBar from '../../e-charts/common-bar';
import screenfull from '../../components/full-screen';
import { flexDpr } from '@/assets/js/flex-dpr.js';
import {
  areaMap,
  projectConstruction,
  projectMap,
  projectStatistics,
  getDeviceCount,
  getCountMember,
} from '@/services/screen/screen';
import WarningState from './components/warning-state';
import AliIcon from '@/components/ali-icon/index.vue';
import { fetchPermission } from '@/services/production/module-auth';
import { Emitter } from '@/views/screen/components/screen-utils';
import {
  LayoutContainer,
  LayoutCard,
  LayoutSelect,
  LayoutButton,
  LayoutTabs,
} from '../../layout';
import DeviceBar from '../../e-charts/device-bar.vue';
import CirclePie from '@/views/screen/e-charts/circle-pie';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import { nowSize } from '@/utils/common';
import AttendanceRanking from './components/attendance-ranking.vue';

flexDpr();

@Component({
  components: {
    ChinaMap,
    VirtualList,
    AreaRank,
    CommonBar,
    WarningState,
    AliIcon,
    LayoutContainer,
    LayoutCard,
    LayoutSelect,
    LayoutButton,
    DeviceBar,
    LayoutTabs,
    CirclePie,
    PieChart,
    AttendanceRanking,
  },
})
export default class ScreenMap extends Vue {
  @Prop({ type: String, default: '' }) projectName;

  isFullScreen = false;
  projectTab = 0;
  handleFullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
      this.isFullScreen = !this.isFullScreen;
      Emitter.$emit('fullscreen');
    }
  }
  mounted() {
    this.init();
  }
  init() {
    this.getProjectListMap();
    this.getTotal();
    this.getProjectStatistics();
    this.getProjectConstructionList();
    this.getDeviceCount();
    this.getCountMember();
    this.$refs.warningState.getWarningStateList(); // 警报最新动态(表格)
    this.$refs.warningState.getAlarmStatistics(); // 警报最新动态(图表)
    this.$refs.attendanceRanking.getAttendanceList(); // 出勤人数项目排行
  }

  dateList = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  totalList = {
    projectCount: {
      text: this.$t('screen.totalProject'),
      count: 0,
      background: require('@/assets/images/theme/home1.png'),
    },
    deviceCount: {
      text: this.$t('screen.deviceTotal'),
      count: 0,
      background: require('@/assets/images/theme/home2.png'),
    },
    todayAlarm: {
      text: this.$t('screen.todayAlarmTotal'),
      count: 0,
      background: require('@/assets/images/theme/home3.png'),
    },
    totalContractAmount: {
      text: '合同总额(万元)',
      count: 0,
      background: require('@/assets/images/theme/home4.png'),
    },
    completedProductionValue: {
      text: '完成产值(万元)',
      count: 0,
      background: require('@/assets/images/theme/home5.png'),
    },
  };

  // 获取项区域统计
  mapAddress = {};
  async getTotal() {
    const params = {
      organizationName: this.projectName,
    };
    if (this.selectedAddress) {
      params['provinceName'] = this.selectedAddress;
    }
    try {
      const res = await areaMap(params);
      const total = res[res.length - 1];
      this.totalList.projectCount.count = total.projectCount;
      this.totalList.deviceCount.count = total.deviceCount;
      this.totalList.todayAlarm.count = total.todayAlarm;
      res.forEach(v => {
        if (v.regionName !== '全国') {
          this.mapAddress[v.regionName] = {
            ...v,
          };
        }
      });
    } catch {
      return false;
    }
  }

  // 获取地图项目列表
  projectListMap = [];
  async getProjectListMap() {
    const params = {
      organizationName: this.projectName,
    };
    if (this.selectedAddress) {
      params['region'] = this.selectedAddress;
    }
    try {
      this.projectListMap = await projectMap(params);
    } catch {
      return false;
    }
  }

  // 获取全国的省市名称
  selectedAddress = '';
  getDbAdress(val) {
    if (val !== '全国') {
      this.selectedAddress = val;
    } else {
      this.selectedAddress = '';
    }
    this.init();
  }

  // 获取项目统计
  projectStatus = {
    NOT_STARTED: this.$t('screen.notBegin'),
    CONSTRUCTION: this.$t('screen.underConstruction'),
    COMPLETED: this.$t('screen.finfishJobs'),
  };
  projectStatisticsList = [];
  projectTotal = 0;
  async getProjectStatistics() {
    const params = {
      organizationName: this.projectName,
    };
    if (this.selectedAddress) {
      params.region = this.selectedAddress;
    }
    try {
      const res = await projectStatistics(params);
      this.projectStatisticsList = this.changeChartData(
        res.statisticsList,
        this.projectStatus,
      );
      this.projectTotal = res.totalCount;
      this.totalList.totalContractAmount.count = res.totalContractAmount;
      this.totalList.completedProductionValue.count =
        res.completedProductionValue;
    } catch {
      return false;
    }
  }

  // 获取在建项目统计排行
  projectConstructionList = [];
  async getProjectConstructionList() {
    try {
      const params = {
        organizationName: this.projectName,
      };
      if (this.selectedAddress) {
        params.region = this.selectedAddress;
      }
      const res = await projectConstruction(params);
      this.projectConstructionList = this.changeChartData(res);
    } catch {
      return false;
    }
  }
  changeChartData(data, obj = {}) {
    return data.map(v => ({
      name: Object.keys(obj).length > 0 ? obj[v.index] : v.index,
      value: v.count,
    }));
  }

  // 获取设备分布统计
  deviceCount = [];
  async getDeviceCount() {
    try {
      const params = {
        organizationName: this.projectName,
      };
      if (this.selectedAddress) {
        params.region = this.selectedAddress;
      }
      this.deviceCount = await getDeviceCount(params);
    } catch {
      return false;
    }
  }

  // 人员统计
  countMember = {
    companyMembers: [],
    roleMembers: [],
    total: 0,
  };
  async getCountMember() {
    try {
      const params = {
        organizationName: this.projectName,
      };
      if (this.selectedAddress) {
        params.region = this.selectedAddress;
      }
      this.countMember = await getCountMember(params);
    } catch {
      return false;
    }
  }

  getMemberTotal(list) {
    let total = 0;
    list.forEach(item => {
      total += item.number;
    });
    return total;
  }
  labelOption(total) {
    return {
      show: true,
      position: 'center',
      formatter: `{a|总}\n{b|${total}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(16),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(20),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
  async beforeRouteEnter(to, from, next) {
    try {
      const res = await fetchPermission('ds');
      const pathList = Object.keys(res);
      if (pathList.includes('/screen')) {
        next();
      } else {
        next('/403');
      }
    } catch {
      next('/403');
    }
  }
}
</script>

<style lang="less" module>
.screenIndex {
  height: 100%;
  display: flex;
  .card {
    width: 4.6rem;
    height: 32.5%;
  }
  .left {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mid {
    flex: 1;
    display: flex;
    flex-direction: column;
    // align-items: center;
    overflow: hidden;
    position: relative;
    .midTop {
      width: 85%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: absolute;
      align-self: center;
      .midCardWrap {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-bottom: 0.24rem;
        }
      }
      .midCard {
        height: 0.9rem;
        font-family: YouSheBiaoTiHei;
        background: center / contain no-repeat;
        position: relative;
        & > div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0.18rem 0 0 1.1rem;
        }
        .midCardBg {
          height: 1.05rem;
          width: auto;
        }
        .count {
          font-size: 0.24rem;
          color: #fff;
        }
        .text {
          color: #9ac0d9;
          font-size: 0.16rem;
          margin-bottom: 0.02rem;
        }
      }
    }
    .map {
      height: 76%;
      margin-top: auto;
    }
  }
}
</style>
