<template>
  <div :class="$style.chart" ref="rank">
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <EmptyContent />
    </div>
    <div v-else ref="barChart" :class="$style.canvas"></div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { nowSize } from '@/utils/common.js';
import { Emitter } from '@/views/screen/components/screen-utils';
import theme from '@/utils/theme/theme';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {
    EmptyContent,
  },
})
export default class AreaRank extends Vue {
  @Prop({ type: String, default: '100%' }) chartWidth;
  @Prop({ type: String, default: '100%' }) chartHeight;
  @Prop({ type: Array, default: () => [] }) chartData;
  @Prop({ type: Boolean, default: false }) showxAxis;
  @Prop({ type: Boolean, default: false }) showAxisTick;
  @Prop({ type: String, default: '' }) chartUnit;
  @Prop({ type: Boolean, default: true }) showBackground;

  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }

  @Watch('chartData', { deep: true })
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.isNoData = false;
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.isNoData = true;
    }
  }

  chartResize() {
    setTimeout(() => {
      const rect = this.$refs.rank.getBoundingClientRect();
      const opt = {
        width: rect.width,
        height: rect.height,
      };
      if (this.myChart) {
        this.myChart.resize(opt);
      }
    }, 100);
  }
  mounted() {
    this.changeData(this.chartData);
    Emitter.$on('fullscreen', this.chartResize);
  }
  beforeDestroy() {
    Emitter.$off('fullscreen', this.chartResize);
  }

  isNoData = true;
  get dataZoom() {
    return [
      {
        type: 'slider',
        show: true,
        start: 100,
        end: 0,
        yAxisIndex: [0],
        width: nowSize(8),
        height: nowSize(210), // 组件高度
        top: nowSize(15),
        left: 0, // 左边的距离
        bottom: nowSize(70), // 底部的距离
        brushSelect: false, // 不能鼠标点击刷选
        fillerColor: '#efefef',
        borderColor: '#eeeeee',
        backgroundColor: '#fff',
        handleStyle: {
          color: 'rgba(0, 0, 0, 0)',
          opacity: 0,
        },
        showDataShadow: false, // 是否显示数据阴影 默认auto
        showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        handleIcon:
          'M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
        filterMode: 'filter',
      },
    ];
  }
  myChart = null;
  initChart() {
    if (!this.myChart) {
      const el = this.$refs.barChart;
      if (!el) return;
      this.myChart = echarts.init(el);
    }
    if (!this.chartData.length) {
      return;
    }
    const legendData = this.chartData.map(v => v.name);
    const zoom = this.dataZoom;
    const length = this.chartData.length;
    if (length > 6) {
      zoom[0].show = true;
      const num = Math.round((5 / length) * 10000) / 100.0;
      zoom[0].end = 100 - Math.floor(num);
    } else {
      zoom[0].show = false;
      zoom[0].start = 100;
    }
    const themeMode = theme['dark'];
    const option = {
      color: ['#4FA2FF'],
      grid: {
        left: '3%',
        bottom: '1%',
        top: '2%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: themeMode.mainBg,
        textStyle: {
          color: themeMode.font,
          fontSize: nowSize(14),
        },
        extraCssText: 'border-radius:5px;border-color: var(--primary-fade-80)',
        padding: [nowSize(10)],
        formatter: `{b0}: {c0}${this.chartUnit}`,
      },
      xAxis: {
        show: this.showxAxis,
        type: 'value',
        axisLabel: {
          color: themeMode.font,
        },
        splitLine: {
          lineStyle: {
            color: themeMode.border,
          },
        },
      },
      yAxis: {
        type: 'category',
        data: legendData,
        splitLine: {
          show: false,
        },
        axisTick: { show: this.showAxisTick, alignWithLabel: true },
        axisLine: {
          show: false,
        },
        axisLabel: {
          width: nowSize(100),
          overflow: 'truncate',
          color: themeMode.font,
        },
      },
      dataZoom: zoom,
      series: [
        {
          name: '总数',
          type: 'bar',
          label: {
            show: true,
            position: 'right',
            color: themeMode.font,
          },
          barWidth: nowSize(15),
          showBackground: this.showBackground,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)',
          },
          data: this.chartData,
        },
      ],
    };
    this.myChart.setOption(option, true);
  }
}
</script>
<style lang="less" module>
.chart {
  // height: calc(100% - 0.3rem);
  height: 100%;
  .canvas {
    height: 100%;
  }
}
</style>
