<template>
  <LayoutCard title="出勤人数项目排行">
    <template slot="action">
      <LayoutTabs
        style="flex: 1"
        :default-value="0"
        :options="[
          { key: 0, text: '图表分析' },
          { key: 1, text: '出勤数据' },
        ]"
        @input="
          idx => {
            activeIndex = idx;
          }
        "
      />
      <a-select
        :options="dateList"
        v-model="date"
        @change="getAttendanceList"
      ></a-select>
    </template>
    <PieChart
      v-if="activeIndex === 0"
      :chartData="projectStatisticsList"
      :size="150"
      :showEllipsis="true"
      :labelOption="labelOption(total)"
      @click="pieClick"
      right="5%"
      left="18%"
    />
    <ScrollTable
      v-else
      :columns="attendanceColumns"
      :data="attendanceList"
    ></ScrollTable>
  </LayoutCard>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { projectAttendanceList } from '@/services/screen/screen';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import { LayoutCard, LayoutTabs } from '../../../layout';
import { createModal } from '@triascloud/x-components';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import { nowSize } from '@/utils/common';
import PeopleManageMentDialog from '@/views/screen/project/people-management/components/peopleManageMentDialog.vue';
import { PublicSize } from '@/views/green-construction/components/utils';
import { getLaborDetail } from '@/services/screen/jiangsu/labor';
import { projectList } from '@/services/device-manage/device';
import { Debounce } from 'lodash-decorators';

@Component({
  components: {
    LayoutCard,
    ScrollTable,
    LayoutTabs,
    PieChart,
    PeopleManageMentDialog,
  },
})
export default class AttendanceRanking extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) dateList;
  @Prop({ type: String, required: true }) projectName;

  mounted() {
    this.getAttendanceList();
  }
  projectStatisticsList = [];
  total = 0;
  date = 'SEVEN_DAY';
  attendanceColumns = [
    {
      title: '项目',
      dataIndex: 'projectName',
      key: 'projectName',
      width: '150px',
    },
    {
      title: '总人数',
      dataIndex: 'memberCount',
      key: 'memberCount',
      format: text => (text < 0 ? '-' : text),
    },
    {
      title: '出勤人数',
      dataIndex: 'attendanceNum',
      key: 'attendanceNum',
      ellipsis: true,
    },
    {
      title: '出勤率',
      dataIndex: 'attendanceRate',
      key: 'attendanceRate',
      format: text => (text >= 0 ? text + '%' : '-'),
    },
  ];
  activeIndex = 0;
  attendanceList = [];
  async getAttendanceList() {
    try {
      const params = {
        current: 1,
        searchDay: this.date,
        organizationName: this.projectName,
        size: 200,
      };
      const { records } = await projectAttendanceList(params);
      this.attendanceList = records;
      this.total = 0;
      this.projectStatisticsList = records.map(item => {
        this.total += item.attendanceNum;
        return {
          name: item.projectName,
          value: item.attendanceNum,
          projectId: item.projectId,
        };
      });
    } catch {
      return false;
    }
  }
  labelOption(total) {
    return {
      show: true,
      position: 'center',
      formatter: `{a|总}\n{b|${total}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(16),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(20),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }

  // 人员信息查询
  workerDetailParams = {
    current: 1,
    keyword: '',
    days: '',
    projectId: '',
    size: 10,
  };
  total = 0;
  workerInfoDialogData = [];
  async getWorkerInfoList() {
    this.loading = true;
    this.workerDetailParams.days = this.date;
    const { records, total } = await getLaborDetail(this.workerDetailParams);
    this.workerInfoDialogData = records;
    this.total = total;
    this.loading = false;
  }
  async pieClick(data) {
    this.workerDetailParams.projectId = data.data.projectId;
    await this.getWorkerInfoList();
    await this.getProjectList();
    await this.showWorkerDialog();
  }
  changeWorkerPagination(data) {
    this.workerDetailParams.size = data.pageSize;
    this.workerDetailParams.current = data.current;
    this.getWorkerInfoList();
  }
  // 弹窗
  title = '工人信息';
  columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '照片',
      dataIndex: 'avatar',
      key: 'avatar',
      scopedSlots: { customRender: 'dImages' },
      width: 200,
    },
    {
      title: '姓名',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 100,
    },
    {
      title: '身份证号',
      dataIndex: 'idCard',
      key: 'idCard',
      ellipsis: true,
      width: 200,
    },
    {
      title: '年龄',
      dataIndex: 'age',
      key: 'age',
      ellipsis: true,
      width: 100,
      customRender(text) {
        return text === -1 ? '-' : text;
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex',
      ellipsis: true,
      width: 60,
    },
    {
      title: '参建单位',
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
      width: 200,
    },

    {
      title: '工种',
      dataIndex: 'jobType',
      key: 'jobType',
      ellipsis: true,
      width: 200,
    },
    {
      title: '岗位',
      dataIndex: 'jobPosition',
      key: 'jobPosition',
      ellipsis: true,
      width: 100,
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'action',
      width: 60,
      scopedSlots: { customRender: 'action' },
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  showWorkerDialog() {
    createModal(
      () => (
        <PeopleManageMentDialog
          showAction
          showForm={false}
          columns={this.columns}
          total={this.total}
          data={this.workerInfoDialogData}
          onChangePagination={this.changeWorkerPagination}
          projectId={this.workerDetailParams.projectId}
          scopedSlots={{
            header: () => (
              <div>
                <AInput
                  style="width: 200px;"
                  placeholder="请输入工人姓名"
                  v-model={this.workerDetailParams.keyword}
                  onInput={() => this.handleSearch()}
                />
                <ASelect
                  v-model={this.workerDetailParams.projectId}
                  placeholder="请选择项目"
                  style="width: 200px;margin-left: 10px;"
                  allowClear
                  onChange={this.getWorkerInfoList}
                >
                  {this.projectList.map(item => (
                    <ASelectOption key={item.pkId} value={item.pkId}>
                      {item.projectName}
                    </ASelectOption>
                  ))}
                </ASelect>
              </div>
            ),
          }}
          loading={this.loading}
        />
      ),
      {
        title: '出勤工人信息',
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  // 获取项目列表
  projectList = [];
  async getProjectList() {
    this.projectList = await projectList();
  }
  @Debounce(500)
  handleSearch() {
    this.getWorkerInfoList();
  }
}
</script>

<style lang="less" module></style>
