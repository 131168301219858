/**
 * @Description 火星坐标系（GCJ-02）与百度坐标系（BD-09）的转换
 * @param lng
 * @param lat
 * @returns {{lng: number, lat: number}}
 */
export function gcj02tobd09(lng, lat) {
  const x_PI = (3.14159265358979324 * 3000.0) / 180.0;
  lat = +lat;
  lng = +lng;
  const z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
  const theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
  const bd_lng = z * Math.cos(theta) + 0.0065;
  const bd_lat = z * Math.sin(theta) + 0.006;
  return {
    lng: bd_lng,
    lat: bd_lat,
  };
}
