var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.screenIndex},[_c('div',{class:_vm.$style.left},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":_vm.$t('screen.itemStatistics')}},[_c('LayoutTabs',{attrs:{"slot":"action","options":[
          { text: '项目', key: 0 },
          { text: '区域', key: 1 },
        ]},slot:"action",model:{value:(_vm.projectTab),callback:function ($$v) {_vm.projectTab=$$v},expression:"projectTab"}}),(!_vm.projectTab)?_c('PieChart',{attrs:{"chartData":_vm.projectStatisticsList,"size":150,"right":"5%","left":"18%"}}):_c('AreaRank',{attrs:{"chart-data":_vm.projectConstructionList,"chartUnit":"个"}})],1),_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":"设备分布情况"}},[_c('DeviceBar',{attrs:{"data":_vm.deviceCount}})],1),_c('div',{class:_vm.$style.card},[_c('attendance-ranking',{ref:"attendanceRanking",attrs:{"dateList":_vm.dateList,"projectName":_vm.projectName}})],1)],1),_c('div',{class:_vm.$style.mid},[_c('div',{class:_vm.$style.midTop},_vm._l((_vm.totalList),function(item,key){return _c('div',{key:key,class:_vm.$style.midCardWrap},[_c('div',{class:_vm.$style.midCard},[_c('img',{class:_vm.$style.midCardBg,attrs:{"src":item.background}}),_c('div',[_c('div',{class:_vm.$style.text},[_vm._v(_vm._s(item.text))]),_c('div',{class:_vm.$style.count},[_vm._v(" "+_vm._s(item.count)+" ")])])])])}),0),_c('div',{ref:"mapHeight",class:_vm.$style.map},[_c('ChinaMap',{attrs:{"mapAddress":_vm.mapAddress,"projectListMap":_vm.projectListMap,"isFullScreen":_vm.isFullScreen},on:{"dbAdress":_vm.getDbAdress}})],1)]),_c('div',{class:_vm.$style.right},[_c('div',{class:_vm.$style.card},[_c('WarningState',{ref:"warningState",attrs:{"dateList":_vm.dateList,"projectName":_vm.projectName,"selectedAddress":_vm.selectedAddress}})],1),_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":"参建单位人数"}},[_c('PieChart',{attrs:{"chartData":_vm.countMember.companyMembers.map(item => ({
            name: item.name,
            value: item.number,
          })),"labelOption":_vm.labelOption(_vm.getMemberTotal(_vm.countMember.companyMembers)),"size":150,"showEllipsis":true,"right":"5%","left":"18%"}})],1),_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":"工种人数"}},[_c('PieChart',{attrs:{"chartData":_vm.countMember.roleMembers.map(item => ({
            name: item.name,
            value: item.number,
          })),"labelOption":_vm.labelOption(_vm.getMemberTotal(_vm.countMember.roleMembers)),"size":150,"showEllipsis":true,"right":"5%","left":"18%"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }