import request from '../request';
import originRequest from '@triascloud/request';

const serviceName = '/iot-electric-box';
/**
 * @name 获取地址位置
 * @param { Object } options
 */
export function getAddressInfoByAmap(options) {
  return request(`${serviceName}/api/map/getAddressInfo`, {
    method: 'GET',
    body: options,
    auth: false,
  });
}

/**
 * @name 获取子区域列表
 * @param { Object } name 区域名称
 */
export function getRegionList(name) {
  return originRequest(`https://restapi.amap.com/v3/config/district`, {
    body: {
      key: '01e97afa086bb1ffe1ecd90aedad8493',
      keywords: name,
    },
  });
}
