<template>
  <div>
    <div>
      <a-select
        v-model="queryParams.projectId"
        placeholder="选择项目"
        style="width: 200px;"
        allowClear
        @change="getWarningList"
      >
        <a-select-option
          v-for="item in projectList"
          :key="item.pkId"
          :value="item.pkId"
        >
          {{ item.projectName }}
        </a-select-option>
      </a-select>
    </div>
    <a-table
      :columns="columns"
      :data-source="warningList"
      :pagination="pagination"
      :loading="loading"
      :transformCellText="({ text }) => text || '-'"
      @change="handlePagination"
      :style="selectStyle(warningList)"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import { getAlarmList } from '@/services/screen/screen';
import { projectList } from '@/services/device-manage/device';

@Component()
export default class ScreenWarningRecord extends Vue {
  @Prop({ type: String, required: true }) date;
  @Prop({ type: String, required: true }) projectId;

  mounted() {
    this.getProjectList();
    this.getWarningList();
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '项目',
        dataIndex: 'projectName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '设备名称',
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '设备码',
        dataIndex: 'deviceCode',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '设备类型',
        dataIndex: 'deviceGroup',
        ellipsis: true,
        customRender: text => deviceTypeEnum[text].deviceName,
      },
      {
        align: 'left',
        title: '报警信息',
        dataIndex: 'reason',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '报警时间',
        dataIndex: 'alarmTime',
        width: 180,
        ellipsis: true,
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
    ];
  }
  deviceGroup = deviceTypeEnum.AIV.deviceName;
  get queryParams() {
    return {
      days: '',
      projectId: this.projectId,
      query: {
        current: 1,
        size: 10,
      },
    };
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    size: 'small',
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  // 表格固定高度
  selectStyle(data) {
    return data.length < 10 ? 'height: 5.5rem;' : 'min-height: 5.5rem;';
  }

  // 获取警报最新动态
  loading = false;
  warningList = [];
  async getWarningList() {
    try {
      this.loading = true;
      this.queryParams.days = this.date;
      const { total, records } = await getAlarmList(this.queryParams);
      this.warningList = records;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.queryParams.query.current = data.current;
    this.queryParams.query.size = data.pageSize;
    this.getWarningList();
  }

  // 获取项目列表
  projectList = [];
  async getProjectList() {
    this.projectList = await projectList();
  }
}
</script>

<style lang="less" module>
.search {
  display: flex;
  justify-content: space-between;
  .itemWrap {
    display: flex;
    margin-bottom: 0.2rem;
  }
  .item {
    margin-right: 0.15rem;
  }
}
</style>
