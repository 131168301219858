<template>
  <LayoutCard :title="$t('screen.alertUpdate')">
    <template slot="action">
      <LayoutTabs
        style="flex: 1.6"
        :default-value="0"
        :options="[
          { key: 0, text: '图表分析' },
          { key: 1, text: '报警数据' },
        ]"
        @input="
          idx => {
            activeIndex = idx;
          }
        "
      />
      <a-select
        :options="dateList"
        v-model="date"
        @change="handleSelect"
      ></a-select>
    </template>
    <PieChart
      v-if="activeIndex === 0"
      :chartData="statisticsList"
      :size="150"
      :showEllipsis="true"
      :labelOption="labelOption(total)"
      right="5%"
      left="18%"
      @click="labelClick"
    />
    <ScrollTable
      :columns="warningColumns"
      :data="warningList"
      @cellClick="cellClick"
      v-else
    ></ScrollTable>
  </LayoutCard>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import { createModal } from '@triascloud/x-components';
import WarningRecord from './warning-record.vue';
import { LayoutCard, LayoutTabs } from '@/views/screen/layout';
import { PublicSize } from '@/views/green-construction/components/utils';
import { nowSize } from '@/utils/common';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import {
  getAlarmList,
  getProjectAlarmStatistics,
} from '@/services/screen/screen';

@Component({
  components: {
    EmptyContent,
    ScrollTable,
    LayoutCard,
    LayoutTabs,
    PieChart,
  },
})
export default class WarningState extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) dateList;
  @Prop({ type: String, required: true }) projectName;
  @Prop({ type: String, required: true }) selectedAddress;

  activeIndex = 0;
  date = 'SEVEN_DAY';
  warningColumns = [
    {
      title: '项目',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: '类型',
      dataIndex: 'subTypeName',
      key: 'subTypeName',
    },
    {
      title: '报警信息',
      dataIndex: 'reason',
      key: 'reason',
      ellipsis: true,
    },
    {
      title: '报警时间',
      dataIndex: 'alarmTime',
      key: 'alarmTime',
      format: text =>
        text ? this.dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '',
    },
  ];
  warningList = [];
  // 获取警报最新动态
  async getWarningStateList() {
    const params = {
      days: this.date,
      organizationName: this.projectName,
      query: {
        current: 1,
        size: 100,
      },
    };
    if (this.selectedAddress) {
      params.region = this.selectedAddress;
    }
    try {
      const { records } = await getAlarmList(params);
      this.warningList = records;
    } catch {
      return false;
    }
  }
  cellClick(data) {
    this.openAlarmDetail(data.record.idxProjectId);
  }
  total = 0;
  statisticsList = [];
  // 获取警报统计
  async getAlarmStatistics() {
    const params = {
      searchDay: this.date,
      organizationName: this.projectName,
      query: {
        current: 1,
        size: 100,
      },
    };
    if (this.selectedAddress) {
      params.region = this.selectedAddress;
    }
    try {
      const { records } = await getProjectAlarmStatistics(params);
      this.total = 0;
      this.statisticsList = records.map(item => {
        this.total += item.count;
        return {
          name: item.projectName || '-',
          value: item.count,
          projectId: item.projectId,
        };
      });
    } catch {
      return false;
    }
  }
  handleSelect() {
    this.getWarningStateList();
    this.getAlarmStatistics();
  }
  labelClick(data) {
    this.openAlarmDetail(data.data.projectId);
  }
  openAlarmDetail(projectId) {
    createModal(
      () => <WarningRecord date={this.date} projectId={projectId} />,
      {
        title: '设备报警信息',
        width: PublicSize.MoreLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  labelOption(total) {
    return {
      show: true,
      position: 'center',
      formatter: `{a|总}\n{b|${total}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(16),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(20),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
}
</script>

<style lang="less" module></style>
